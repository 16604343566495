<template>
  <v-container fluid class='report-card align-self-sm-stretch pa-0'>
    <v-col class='pa-0 ma-0'>
      <v-row class='pa-0 ma-0 d-flex justify-space-between'>
        <span class='report-title'> {{ $t('COLETA_PRESENCIAL_POR_OPERADOR_REPORT_TITLE') }} </span>
        <v-icon
          class='mr-4'
          small
          @click='fetchWidgetContent'
        >
          mdi-reload
        </v-icon>
      </v-row>
      <v-divider />
    </v-col>
    <v-row class='d-flex justify-center mt-2'>
      <v-card-text v-if='hasData'>
        <v-row align='center' justify='center'>
          <apexchart
            :key='chartKey'
            ref='chart'
            type='donut'
            width='500'
            :options='chartOptions'
            :series='series'
          />
        </v-row>
      </v-card-text>
      <span v-else>
        {{ $t('NO_DATA') }}
      </span>
    </v-row>
    <v-row class='report-footer'>
      <v-col>
        <v-icon @click='openFilters'>mdi-filter-outline</v-icon>
      </v-col>
      <v-col>
        <v-btn
          text
          color='primary'
          @click='fetchReportsContent'
        >
          Exportar
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog
      v-model='filterDialog'
      fullscreen
      hide-overlay
      transition='dialog-bottom-transition'
    >
      <v-card>
        <Filters
          :all-locations='allLocations'
          @applyFilters='fetchWidgetContent'
          @close='filterDialog = false'
        />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import * as XLSX from 'xlsx';
  import { format } from 'date-fns';
  import Swal from 'sweetalert2';

  export default {
    components: {
      Filters: () => import('./Filters.vue'),
    },
    data () {
      return {
        reportName: 'Coleta presencial agrupada por localidade',
        locations: null,
        allLocations: [],
        chartKey: 1,
        filterDialog: false,
        chartActive: true,
        hasData: true,
        stationsFilters: null,
        dateFilter: null,
        operatorFilter: null,
        filters: {},
        chartOptions: {
          chart: {
            width: 500,
            type: 'donut',
          },
          labels: [],
          plotOptions: {
            pie: {
              donut: {
                size: '65%',
                background: 'transparent',
                labels: {
                  show: true,
                  name: {
                    show: true,
                    fontSize: '22px',
                    fontFamily: 'Markpro',
                    fontWeight: 400,
                    color: undefined,
                    offsetY: 14,
                    formatter: function (val) {
                      return val;
                    },
                  },
                  value: {
                    show: true,
                    showAlways: true,
                    fontSize: '22px',
                    fontFamily: 'Markpro',
                    fontWeight: 600,
                    color: undefined,
                    offsetY: -18,
                    formatter: function (val) {
                      return val;
                    },
                  },
                  total: {
                    show: true,
                    showAlways: true,
                    label: 'Total',
                    fontFamily: 'Markpro',
                    fontWeight: 400,
                    color: '#373d3f',
                    formatter: function (w) {
                      return w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                    },
                  },
                },
              },
            },
          },
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: 'bottom',
              },
            },
          }],
        },
        series: [],
      };
    },
    mounted: function () {
      this.fetchWidgetContent();
    },
    methods: {
      fetchWidgetContent: async function (filters) {
        this.cleanData();
        let stationFilter = '';
        let dateFilter = '';
        let userFilter = '';

        if (filters) {
          if (filters.stationsFilter && filters.stationsFilter.length > 0) {
            stationFilter = `p.station::JSON->>'id' in (${ filters.stationsFilter.map((p) => `'${ p }'`).join(',') })`;
            this.filters.stationFilter = stationFilter;
          }
          if (filters.operatorsFilter && filters.operatorsFilter.length > 0) {
            userFilter = `p.user_id in (${ filters.operatorsFilter.map((p) => `${ p }`).join(',') })`;
            this.filters.userFilter = userFilter;
          }
          if (filters.dateFilter) {
            if (filters.dateFilter.startDate) {
              dateFilter += `p.created_at >= '${ filters.dateFilter.startDate }'`;
            }
            if (filters.dateFilter.endDate) {
              dateFilter += `${ dateFilter ? ' and ' : '' } p.created_at <= '${ filters.dateFilter.endDate }'`;
            }
            this.filters.dateFilter = dateFilter;
          }
        }

        this.filterDialog = false;
        const { data } = await axios({
          url: '/report-data',
          baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
          method: 'GET',
          responseType: 'json',
          withCredentials: true,
          params: {
            name: this.reportName,
            filter: JSON.stringify({
              stations_filter: stationFilter,
              user_filter: userFilter || '',
              date_filter: dateFilter || '',
            }),
          },
        });
        this.locations = data && data.data ? data.data : null;
        if (this.locations.length > 0) {
          this.hasData = true;
          for (let i = 0; i < this.locations.length; i++) {
            this.series.push(parseInt(this.locations[i].total, 10));
            if (!this.allLocations || (this.allLocations && !this.allLocations.length)) {
              this.allLocations.push(this.locations[i].location);
            }
            this.chartOptions.labels.push(this.locations[i].location);
          }
        } else {
          this.hasData = false;
        }
        this.chartKey++;
      },
      cleanData () {
        // this.allLocations = [];
        this.series = [];
        this.chartOptions.labels = [];
        this.locations = null;
        this.filters = {};
      },
      removeWidget (id) {
        document.removeItem(id);
      },
      openFilters () {
        this.filterDialog = true;
      },
      async fetchReportsContent () {
        const { data } = await axios({
          url: '/report-data',
          baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
          method: 'GET',
          responseType: 'json',
          withCredentials: true,
          params: {
            name: this.reportName,
            filter: JSON.stringify({
              stations_filter: this.filters.stationFilter || '',
              user_filter: this.filters.userFilter || '',
              date_filter: this.filters.dateFilter || '',
            }),
            export: true,
          },
        });

        const reportValues = data && data.data && data.data[0] ? data.data : null;

        if (!reportValues) {
          Swal.fire({
            icon: 'warning',
            title: 'Aviso',
            text: 'Não há dados para exportação',
            allowOutsideClick: false,
            allowEscapeKey: false,
            showConfirmButton: true,
            confirmButtonColor: this.$vuetify.theme.defaults.light.secondary,
          });
          return;
        }

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(
          reportValues.map((value) => { // eslint-disable-line
            return {
              Nome: value.full_name,
              CPF: value.cpf,
              Estação: value.location,
              Operador: value.username,
              'Data de criação': format(new Date(value.created_at), 'dd/MM/yyyy'),
            };
          }) // eslint-disable-line
        );
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, `Relatório - ${ this.reportName } - ${format(new Date(), 'dd/MM/yyyy')}.xlsx`);
      },
    },
  };
</script>
<style>
  html {
   overflow: hidden;
  }
  .report-card {
    background-color: #FFFFFF;
    height: 100vh;
    overflow-y: hidden;
    overflow-x: hidden;
  }
  .content-value {
    margin-top: 20vh !important;
  }
  .card-title {
    min-height: 30%;
  }
  .report-footer {
    position: absolute;
    bottom: 10vh;
    left: 2vw;
  }
  .active-filters-text {
    font-size: 13px;
  }
</style>
